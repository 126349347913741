import React, { useLayoutEffect, useRef, useState } from "react"
import videojs from "video.js"

export default ({ videoSrc, poster }) => {
  const [key, setKey] = useState(0)
  let videoNode = useRef(null)
  let player = undefined
  const options = {
    autoplay: false,
    controls: true,
    sources: [
      {
        src: videoSrc,
        type: "video/mp4",
      },
    ],
  }

  useLayoutEffect(() => {
    player = videojs(videoNode.current, options).ready()
    return () => {
      if (player) {
        player.dispose()
        setKey(key + 1)
      }
    }
  }, [videoSrc])

  return (
    <div data-vjs-player key={`video-${key}`}>
      <video ref={videoNode} className="video-js vjs-16-9" poster={poster}></video>
    </div>
  )
}
