import React from "react"
import VideoPlayer from "./VideoPlayer"

const defaultLinkText = column => {
  return "Se mere her"
}

const replacePoster = s => s.replace(/\.mp4/gi, "_poster.png")

const Tile3 = ({ items, linkText = defaultLinkText }) => {
  const chunkSize = 3

  const rows = [].concat.apply(
    [],
    items.map(function(_, i) {
      return i % chunkSize ? [] : [items.slice(i, i + chunkSize)]
    })
  )

  return (
    <>
      {rows.map((row, rowIndex) => {
        return (
          <div key={rowIndex} className="tile is-ancestor">
            {row.map((column, columnIndex) => {
              return (
                <div key={`${rowIndex}_${columnIndex}`} className="tile is-vertical is-parent is-4">
                  <div className="tile is-parent is-vertical box">
                    <div className="tile is-child">
                      <VideoPlayer videoSrc={column} poster={replacePoster(column)} />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )
      })}
    </>
  )
}

export default Tile3
